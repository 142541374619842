import React from 'react';
import './stylesheets/App.css';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Screen1 from './screens/Screen1';
import Screen2 from './screens/Screen2';
import Screen3 from './screens/Screen3';
import { DataProvider } from './components/DataContext';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  document.documentElement.lang = 'en';
  
  return (
    <Router>
      <DataProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/project" element={<ProtectedRoute element={<Screen1 />} />} />
          <Route path="/xrcapsule" element={<ProtectedRoute element={<Screen2 />} />} />
          <Route path="/login" element={<ProtectedRoute element={<Screen3 />} />} />
        </Routes>
      </DataProvider>
    </Router>
  );
}

export default App;