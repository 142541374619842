import React, { useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, GizmoHelper, GizmoViewport } from '@react-three/drei';
import Model from './Model';
import Rectangle from './Rectangle';
import Cylinder from './Cylinder';
import Screens from './Screens';
import DefaultModel from './DefaultModel';
import '../stylesheets/ThreeScene.css';
import { OrthographicCamera } from '@react-three/drei';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faBinoculars } from '@fortawesome/free-solid-svg-icons';

const ThreeScene = React.memo(({ hiddenModels, height, width, deviceData, modelFiles, setModelFiles, selectedModel, setSelectedModel}) => {
  const topViewPosition = [0, 50, 0];
  const [isTopView, setIsTopView] = useState(false);
  const [altPressed, setAltPressed] = useState(false);
  const [targetPosition, setTargetPosition] = useState([0, 0, 0]);
  const [cameraPosition, setCameraPosition] = useState([0, 20, 20]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey) {
        setAltPressed(true);
      }
    };
  
    const handleKeyUp = (event) => {
      if (!event.altKey) {
        setAltPressed(false);
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return (
    <div className='container-TS'>
      <Canvas className='canvas-TS'>
      <axesHelper args={[25]} />
        <color attach="background" args={['#000000']} />
        <ambientLight intensity={2.5} />
        <pointLight position={[10, 10, 10]}/>
        {modelFiles.map((modelFile, index) => (
          hiddenModels.includes(index) ? null : (
          <Model
            key={index}
            position={modelFile.position}
            index={index}
            gltf={URL.createObjectURL(modelFile.file)}
            modelName={modelFile.name}
            scale={modelFile.scale}
            rotation={modelFile.rotation}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            setModelFiles={setModelFiles}
          />
          )
        ))}
        <Rectangle height={height} width={width} position={[0, 0, 0]} rotationX={Math.PI/2} rotationY={Math.PI/2} rotationZ={Math.PI/2} />
        {parseInt(deviceData) === 1 && (
          <>
            <DefaultModel
              key="desk"
              gltf={`${process.env.PUBLIC_URL}/desk.glb`}
              position={[0, 0.5, -8]}
              scale={1.87}
            />
            <DefaultModel
              key="presenter"
              gltf={`${process.env.PUBLIC_URL}/presenter.glb`}
              position={[0, 0.5, -9.5]}
              scale={1.75}
            />
            <Screens height={3} width={5} position={[-3, 3, -10]} rotationY={Math.PI/-4}/>
            <Screens height={3} width={5} position={[3, 3, -10]} rotationY={Math.PI/4} />
            <Cylinder height={0.5} radius={3} position={[0, 0.25, -8]} />
            <Cylinder height={0.5} radius={2.5} position={[0, 0.25, 8]} />
          </>
        )}
        <gridHelper args={[100, 100]} />
        {isTopView ? (
          <OrthographicCamera
            makeDefault
            position={topViewPosition}
            zoom={15}
            near={0.1}
            far={1000}
          />
        ) : (
          <PerspectiveCamera position={cameraPosition} makeDefault />
        )}
        <OrbitControls target={targetPosition} enableRotate={!isTopView && altPressed} />
        <GizmoHelper alignment="bottom-right" margin={[100, 100]}>
          <GizmoViewport labelColor="white" axisHeadScale={1} />
        </GizmoHelper>
        </Canvas>
      <button className='button-TS' onClick={() => setIsTopView(!isTopView)}>
        {isTopView ? <FontAwesomeIcon icon={faCube} className='icon-TS' /> : <FontAwesomeIcon icon={faBinoculars} className='icon-TS' />}
      </button>
    </div>
  );
});

export default ThreeScene;
