import React, { useState, useEffect } from 'react';
import '../stylesheets/ProjectData.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faCog, faHouse } from '@fortawesome/free-solid-svg-icons';
import { NotificationModal } from './../screens/Screen2';
import { useNavigate } from 'react-router-dom';

function Popup2({ handleClose, show, jsonString}) {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleDownloadJson = () => {
    const blob = new Blob([jsonString], { type: 'application/json' });
    const a = document.createElement('a');
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = 'XRCapsule.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    setNotificationMessage('JSON descargado exitosamente');
    setShowNotification(true);

    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const copyJsonToClipboard = () => {
    navigator.clipboard.writeText(jsonString).then(() => {
      setNotificationMessage('JSON copiado al portapapeles');
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    });
  };

  const showHideClassName = show ? 'modal-json display-block' : 'modal-json display-none';

  return (
    <div>
      <div className={showHideClassName}>
        <section className="modal-main">
          <div className="close-button-PuP" onClick={handleClose}>
            <FontAwesomeIcon style={{ fontSize: '16px' }} icon={faTimes} />
          </div>
          <h3>Code</h3>
          <textarea id="JsonUnityCode"
            rows={20}
            value={jsonString} // Aquí se actualiza el contenido del textarea
            spellCheck="false"
            readOnly
          />
          <div className="button-json-container">
            <button className="download-json-button" onClick={handleDownloadJson}>Download JSON</button>
            <button className="download-json-button" onClick={copyJsonToClipboard}>Copy to Clipboard</button>
          </div>
        </section>
      </div>
      {showNotification && <NotificationModal message={notificationMessage} handleClose={() => setShowNotification(false)} />}
    </div>
  );
}

const ProjectData = ({ projectNameData, modelFiles, fileInputRef, handleModelUpload, loading, onToggleObjectData, data }) => {
  const [showPopup2, setShowPopup2] = useState(false);
  const navigate = useNavigate(); 
  const [jsonString, setJsonString] = useState('');

  const generateJson = (modelFiles, data) => {
      if(data == null){
        const projectName = "Project Name";
        const device = "0";
        const deviceText = "Augmented Reality";
        const dimensionW = 25;
        const dimensionH = 25;
        const units = "Meters";
        
        data = {
          projectName,
          device,
          deviceText,
          units,
          dimensions: { w: dimensionW, h: dimensionH },
        };
      }
    const capsules = [{
      Name: data.projectName,
      TargetDevices: ['Virtual Production', 'Smartphone VR', 'Smartphone AR'],
      Workspace: {
        Volume: [data.dimensions.w, data.dimensions.h, data.dimensions.h],
        Unit: data.units
      },
      Assets: modelFiles.map((file) => ({
        AssetUUID: file.AssetUUID,
        Name: file.folderName,
        Type: "3D.Model",
        SourceURL: file.urlProcessedFile,
        Metadata: {
          XReco: {
            Notes: "3D Model Processor Service",
            BoundingBox: [1.0, 1.0, 1.0],
            Polygons: 0,
            Resolution: null,
          },
        },
      })),

      Scene: {
        Objects: modelFiles.map((file) => ({
          Name: file.folderName,
          AssetUUID: file.AssetUUID,
          SceneUUID: file.SceneUUID,
          Visible: file.visible,
          Enabled: true,
          Transform: {
            Position: file.position,
            Rotation: file.rotation,
            Scale: file.scale,
          },
        })),
        Triggers: modelFiles
          .filter(file => file.triggers !== null && file.triggers.length > 0) // Filtra los archivos con triggers
          .flatMap(file => 
            file.triggers.map(trigger => ({  // Mapea sobre cada trigger
              Type: trigger.actionText,
              TriggerParameters: [
                `time: ${trigger.time}`,
                `action: ${trigger.action}`,
              ],
              SceneTargetUUID: file.SceneUUID,
              OnActivate: ''
            }))
          ),
      },
    }];

    return JSON.stringify({
      $schema: 'https://xrcapsule.visyon.tech/schema#',
      Metadata: {
        FileVersion: '0.0.2',
        XRCapsuleEditorVersion: '1.0.0',
      },
      Capsules: capsules,
    }, null, 2);
  };

  const togglePopup2 = () => {
    console.log(modelFiles);
    console.log(data);
    const json = generateJson(modelFiles, data);
    setJsonString(json);
    setShowPopup2(!showPopup2);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const navigateToProject = () => {
    navigate('/project');
  };

  return (
    <div className='contenedor-form'>
      <div className="boton-persIcon-container">
        <div className="boton-persIcon" onClick={navigateToProject} title="Home">
          <FontAwesomeIcon icon={faHouse} />
        </div>
        {!loading && (
          <div className="boton-persIcon" onClick={handleButtonClick} title="Upload Asset">
            <FontAwesomeIcon icon={faPlus} />
          </div>
        )}
        <div className='field'>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            webkitdirectory="true"
            directory="true"
            onChange={handleModelUpload}
            style={{ display: 'none' }}
            disabled={loading}
          />
        </div>
        {loading && <div className="loader"></div>}
      </div>
      <div className="nombre-proyecto-container">
        <div className="nombre-proyecto">{projectNameData}</div>
      </div>
      <div className="button-code-container">
        <div className="boton-persIcon" onClick={onToggleObjectData} title="Settings">
          <FontAwesomeIcon icon={faCog} />
        </div>
        <button onClick={togglePopup2} title="Code" className="button-code"> &lt; / &gt; Code </button>
      </div>
      <Popup2
        show={showPopup2}
        handleClose={togglePopup2}
        data={data}
        modelFiles={modelFiles}
        jsonString={jsonString}
      />
    </div>
  );
};

export default ProjectData;