import React, { useState, useEffect } from 'react';
import '../stylesheets/ObjectData2.css';

const ObjectData2 = ({heightTS, widthTS, onWidthChange, onHeightChange, data}) => {

  const [width, setWidth] = useState(widthTS);
  const [height, setHeight] = useState(heightTS);

  useEffect(() => {
    setWidth(widthTS);
    setHeight(heightTS);
  }, [widthTS, heightTS]);
  
  const handleWidthChange = (e) => {
    const value = Number(e.target.value);
    if (value <= 100) {
      setWidth(value);
    } else {
      setWidth(100);
    }
  };

  const handleHeightChange = (e) => {
    const value = Number(e.target.value);
    if (value <= 100) {
      setHeight(value);
    } else {
      setHeight(100);
    }
  };

  const handleApply = () => {
    onWidthChange(width);
    onHeightChange(height);
    data.dimensions.w = width;
    data.dimensions.h = height;
  };

  return (
    <div className='object-wks-container'>
      <h3>Workspace</h3>
      <hr style={{ borderColor: '#3a3e47', marginTop: '20px', marginBottom: '35px' }} />
      <div className='field-wks-container'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label className='labelApp' style={{ marginRight: '10px' }}>Device: {data.deviceText}</label>
        </div>
        <br />
      <form style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label className='labelApp' style={{ marginRight: '10px' }}>
            W
          </label>
          <input className='rounded-input' type="number" name="width" min="0" max="100" value={width} onChange={handleWidthChange} />
          <label className='labelApp' style={{ marginRight: '14px', marginLeft: '5px' }}>{data.units}</label>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label className='labelApp' style={{ marginRight: '14px' }}>
            H
          </label>
          <input className='rounded-input' type="number" name="height" min="0" max="100" value={height} onChange={handleHeightChange} />
          <label className='labelApp' style={{ marginRight: '14px', marginLeft: '5px' }}>{data.units}</label>
        </div>
        <button type="button" className='apply-button' onClick={handleApply}>Apply</button>
      </form>
        <br />
      </div>
    </div>
  );
};

export default ObjectData2;
