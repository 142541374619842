// Screen3.js
import React, { useEffect, useState, useContext } from 'react';
import NavBarLogin from '.././components/NavBarLogin';
import Login from '.././components/Login';

const Screen3 = () => {
  const [userName, setUserName] = useState('LOGIN');

  return (
    <div>
      <NavBarLogin userName={userName}/>
      <Login />
    </div>
  );
};

export default Screen3;