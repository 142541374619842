import React, { useState, useRef, useEffect } from 'react';
import { useGLTF, PivotControls } from '@react-three/drei';
import { Vector3, Quaternion, Euler } from 'three';

const Model = React.memo (({ position, index, gltf, modelName, scale, rotation, selectedModel, setSelectedModel, setModelFiles}) => {
  const { scene } = useGLTF(gltf);
  const [currentPosition, setCurrentPosition] = useState(position);
  const [currentRotation, setCurrentRotation] = useState(rotation);
  const [currentScale, setCurrentScale] = useState(scale);
  
  const meshRef = useRef();

  useEffect(() => {
    if (selectedModel && selectedModel.index === index) {
      setCurrentPosition(selectedModel.currentPosition);
      setCurrentRotation(selectedModel.currentRotation);
      setCurrentScale(selectedModel.currentScale);
    }
  }, [selectedModel, index]);

  const handleModelDrag = (l, dl, w, dw) => {
    if (!scene) return;

    const newPosition = new Vector3();
    const rotation = new Quaternion();
    w.decompose(newPosition, rotation, new Vector3());

    setCurrentPosition(newPosition.toArray());

    const eulerRotation = new Euler().setFromQuaternion(rotation);
    setCurrentRotation([eulerRotation.x, eulerRotation.y, eulerRotation.z]);
  };

  const handleDragEnd = () => {
    const finalPosition = new Vector3();
    meshRef.current.position.toArray(finalPosition);

    const finalQuaternion = new Quaternion();
    meshRef.current.quaternion.toArray(finalQuaternion);
    const finalEuler = new Euler().setFromQuaternion(finalQuaternion);
  };

  return (
    <group
      ref={meshRef}
      position={currentPosition}
      scale={currentScale}
      rotation={currentRotation}
      onClick={(e) => {
        e.stopPropagation();
        console.log("onModelClick:", index,currentPosition, currentRotation, currentScale, modelName);
        setSelectedModel({ index, currentPosition, currentRotation, currentScale, modelName});
        setModelFiles(prevModelFiles => {
          const updatedModelFiles = [...prevModelFiles];
          updatedModelFiles[index] = {
            ...updatedModelFiles[index],
            position: currentPosition,
            scale: currentScale,
            rotation: currentRotation
          };
          return updatedModelFiles;
        });
      }}
    >  
      <PivotControls
        depthTest={false}
        anchor={[0, 0, 0]}
        scale={64}
        lineWidth={3.5}
        onDrag={handleModelDrag}
        onDragEnd={handleDragEnd}
        fixed={true}
        autoTransform={true}
      >
        <primitive object={scene} />
      </PivotControls>
    </group>
  );
});

export default Model;
