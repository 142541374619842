// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ element, ...rest }) => {
  
  const location = useLocation();

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const isAuthenticated = () => {
    const userData = getCookie('userData');
    if (userData) {
      try {
        const { username, password } = JSON.parse(userData);
        return username === 'admin' && password === 'xrcapsuleproject';
      } catch (e) {
        return false;
      }
    }
    return false;
  };

  if (isAuthenticated() && location.pathname === '/login') {
    return <Navigate to="/project" />;
  }

  if (!isAuthenticated() && location.pathname === '/login') {
    return element;
  }

  return isAuthenticated() ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
