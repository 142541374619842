// Screen2.js
import React , { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '.././components/NavBar';
import ThreeScene from '.././components/ThreeScene';
import ObjectData from '.././components/ObjectData';
import ObjectData2 from '.././components/ObjectData2';
import AssetData from '../components/AssetData';
import DataContext from '.././components/DataContext';
import ProjectData from '.././components/ProjectData';

export function NotificationModal({ message, handleClose }) {
  return (
    <div className="notification-modal display-block">
      <section className="notification-main">
        <div className="notification-message">
          <p>{message}</p>
        </div>
        <button className="close-notification" onClick={handleClose}>Close</button>
      </section>
    </div>
  );
}

const Screen2 = () => {

  let { data } = useContext(DataContext);
  const navigate = useNavigate(); 

  useEffect(() => {
    if(data == null){
      navigate('/project');
    }
  }, []);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const [wData, setWData] = useState(50);
  const [hData, setHData] = useState(50);
  const [projectNameData, setProjectNameData] = useState("Project Name");
  const [deviceData, setDeviceData] = useState(0);

  let [numberOfItems, setNumberOfItems] = useState(0); // # of models
  const [userName, setUserName] = useState('');

  useEffect(() => {
    if (data) {
      if (data.dimensions.w && data.dimensions.h && data.projectName) {
        setWData(data.dimensions.w);
        setHData(data.dimensions.h);
        setProjectNameData(data.projectName);
        setDeviceData(data.device);
      }
      else if(data.device){
        setDeviceData(data.device);
      }
      if(data.basketData){
        setNumberOfItems(data.basketData.items.length);
      }
    }
  }, [data]);
    
  useEffect(() => {
    if (numberOfItems > 0) {
      const timeoutId = setTimeout(() => {
        if (data && data.basketData) {
          setUserName(data.basketData.basket_name);
          let dataName = data.basketData.items[numberOfItems-1].name;
        }
        else{
          
        }
        setNumberOfItems((prevNumberOfItems) => prevNumberOfItems - 1);
      }, 0);
      return () => clearTimeout(timeoutId);
    }
  }, [numberOfItems]);

  const [modelFiles, setModelFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleModelUpload = async (event) => {
    setLoading(true);
    const files = event.target.files;
    const formData = new FormData();
  
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
      formData.append('paths', files[i].webkitRelativePath || files[i].name);
    }
  
    try {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://flask-xrcapsule.visyon.tech/read_3D_model');
      xhr.responseType = 'blob';

      xhr.onload = function() {
        if (xhr.status === 200) {
          setLoading(false);
          const processedFile = xhr.response;
          const contentDisposition = xhr.getResponseHeader('Content-Disposition');
          const OriginalFolderName = xhr.getResponseHeader('Original-Folder-Name');
          const TempFolderName = xhr.getResponseHeader('Temp-Folder-Name');
          const fileListString = xhr.getResponseHeader('File-List');
          if (contentDisposition) {
            const fileName = contentDisposition.split('filename=')[1].trim();
            
            /*const downloadUrl = URL.createObjectURL(processedFile);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(downloadUrl);*/

            const randomPosition = [Math.random() * 10 - 5, 1.75, Math.random() * 10 - 5];
            const zposition = [0, 0, 0];
            const initialScale = [1, 1, 1];
            const initialRotation = [0, 0, 0];

            const assetUUID = crypto.randomUUID();
            const sceneUUID = crypto.randomUUID();
            const triggers = null;

            setModelFiles([...modelFiles, {
              file: processedFile, 
              name: fileName, 
              fileList: fileListString, 
              folderName: OriginalFolderName,
              urlProcessedFile: `https://flask-xrcapsule.visyon.tech/download_procesed_file/${TempFolderName}/${fileName}`, 
              position: randomPosition, 
              scale: initialScale, 
              rotation: initialRotation,
              AssetUUID: assetUUID,
              SceneUUID: sceneUUID,
              visible: true,
              triggers: triggers
            }]);
                       
            setNotificationMessage("This is a test application, if you want to import your result in JSON format to the Unity application the material will only be available in the next 60 minutes.");
            setShowNotification(true);
            //console.log(`https://flask-xrcapsule.visyon.tech/download_procesed_file/${TempFolderName}/${fileName}`);
            
            let fileList = [];
            try {
                fileList = JSON.parse(fileListString);
            } catch (error) {
                console.error('Error parsing File-List:', error);
                return;
            }            
            if (Array.isArray(fileList)) {
              fileList.forEach(fileName => {
                  const fileUrl = `https://flask-xrcapsule.visyon.tech/download_file/${TempFolderName}/${OriginalFolderName}/${fileName}`;
                  //console.log(`${fileUrl}`);
              });
            } else {
                console.error('File-List no es un array:', fileList);
            }

          } else {
            console.error('El encabezado Content-Disposition no está presente en la respuesta.');
          }
        } else {
          throw new Error('Error al cargar el archivo');
        }
      };

      xhr.onerror = function() {
        console.error('Error de red al cargar el archivo.');
      };

      xhr.send(formData);
    } catch (error) {
      console.error('Error:', error);
    }
  
    event.target.value = '';
  };  
  
  const [selectedModel, setSelectedModel] = useState(null);  
  const [hiddenModels, setHiddenModels] = useState([]);

  const hideModel = (modelIndex) => {
    if(selectedModel == null){
      setHiddenModels(prevHiddenModels => {
        if (prevHiddenModels.includes(modelIndex)) {
          setModelFiles(prevModelFiles => {
            const updatedModelFiles = [...prevModelFiles];
            updatedModelFiles[modelIndex] = {
              ...updatedModelFiles[modelIndex],
              visible: true,
            };
            return updatedModelFiles;
          });
          return prevHiddenModels.filter(index => index !== modelIndex);
        } else {
          setModelFiles(prevModelFiles => {
            const updatedModelFiles = [...prevModelFiles];
            updatedModelFiles[modelIndex] = {
              ...updatedModelFiles[modelIndex],
              visible: false,
            };
            return updatedModelFiles;
          });
          return [...prevHiddenModels, modelIndex];
        }
      });
    }
  };

  const [isObjectDataVisible, setIsObjectDataVisible] = useState(false);

  const toggleObjectData = () => {
    setIsObjectDataVisible(prev => !prev);
  };

  const handleWidthChange = (newWidth) => {
    setWData(newWidth);
  };

  const handleHeightChange = (newHeight) => {
    setHData(newHeight);
  };

  return (
    <div>
      <NavBar userName={userName} setUserName ={setUserName}/>      
      <div className='contenedor-canvas'>
        <AssetData 
          hideModel={hideModel} 
          hiddenModels={hiddenModels} 
          modelFiles={modelFiles}
        />
        <ProjectData 
          projectNameData={projectNameData} 
          modelFiles={modelFiles} 
          fileInputRef={fileInputRef} 
          handleModelUpload={handleModelUpload} 
          loading={loading} 
          onToggleObjectData={toggleObjectData} 
          data={data}
        />
        <ThreeScene 
          hiddenModels={hiddenModels} 
          height={hData} 
          width={wData}
          deviceData={deviceData} 
          modelFiles={modelFiles} 
          setModelFiles={setModelFiles}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
        />
        <ObjectData 
        selectedModel={selectedModel} 
        setSelectedModel={setSelectedModel}
        modelFiles={modelFiles} 
        setModelFiles={setModelFiles}
        />
        {isObjectDataVisible && 
        <ObjectData2 
          heightTS={hData} 
          widthTS={wData} 
          onWidthChange={handleWidthChange} 
          onHeightChange={handleHeightChange} 
          data={data} 
        />}
      </div>
      {showNotification && 
      <NotificationModal 
        message={notificationMessage} 
        handleClose={() => setShowNotification(false)} 
      />}
    </div>
  );
};

export default Screen2;
