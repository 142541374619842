import React, { useState, useEffect, useRef} from 'react';
import '../stylesheets/Login.css';
import { useNavigate } from 'react-router-dom';

function PopupLogin({ handleClose, show }) {
  const showHideClassName = show ? 'modalLogin display-block' : 'modalLogin display-none';
  const navigate = useNavigate(); 
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (show) {
      usernameRef.current.focus();
    }
  }, [show]);

  const handleSubmit = (event) => {
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;

    if (password !== 'xrcapsuleproject') { 
      setErrorMessage('Invalid user credentials');
      usernameRef.current.value = '';
      passwordRef.current.value = '';
      usernameRef.current.focus();
      return;
    }

    const userData = {
      username,
      password,
    };

    const setCookie = (name, value, hours, minutes) => {
      const date = new Date();
      const totalMinutes = (hours * 60) + minutes;
      date.setTime(date.getTime() + (totalMinutes * 60 * 1000));
      const expires = `expires=${date.toUTCString()}`;
      document.cookie = `${name}=${value};${expires};path=/`;
    };

    setCookie('userData', JSON.stringify(userData), 1, 0);

    handleClose();
    navigate('/project');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <h2 className="h2L">LOGIN</h2>
        <form className='formPopUpL' onSubmit={(e) => e.preventDefault()}>
        {errorMessage && (
            <p className="error-message">{errorMessage}</p>
          )}
          <label className='labelPopUpL' htmlFor="username">Username</label>
          <input className='inputPopUpL' type="text" id="username" name="username" autoComplete="off" placeholder='Username' ref={usernameRef}/>

          <label className='labelPopUpL' htmlFor="password">Password</label>
          <input className='inputPopUpL' type="password" id="password" name="password" placeholder='Password' autoComplete="off" ref={passwordRef} onKeyDown={handleKeyDown}/>

          <button className="popup-button-inL" type="submit" onClick={() => {handleSubmit()}}>
            Login
          </button>
        </form>
      </section>
    </div>
  );
}

function Login() {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    togglePopup();
  }, []);

  return (
    <div className="App-project">
      <div className="gradient-backgroundL">
        <PopupLogin show={showPopup} handleClose={togglePopup} />
      </div>
    </div>
  );
}

export default Login;
