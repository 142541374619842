// Screen1.js
import React, { useEffect, useState, useContext } from 'react';
import NavBar from '.././components/NavBar';
import Project from '.././components/Project';
import axios from 'axios';
import DataContext from '.././components/DataContext';

const Screen1 = () => {
  const [projectData, setProjectData] = useState(null);
  const [userName, setUserName] = useState('');
  const { updateData } = useContext(DataContext);

  const handleProjectData = (data) => {
    const modifiedData = {
      ...data,
      basketData: basketData
    };
    setProjectData(modifiedData);
    updateData(modifiedData);
  };

  const [fetchDone, setFetchDone] = useState(false);
  const [basketData, setbasketData] = useState(null);
  const [basketId, setbasketId] = useState(null);
  const [accessToken, setaccessToken] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const basketId = queryParams.get('basket_id');
    const accessToken = queryParams.get('access_token');
    setbasketId(basketId);
    setaccessToken(accessToken);

    const fetchData = async () => {
      try {
        if (accessToken != null) {
          setFetchDone(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (accessToken && !basketData) {
      const getbasketData = async () => {
        try {
          const response = await axios.get(
            'https://xreco.ari-imet.eu/api/baskets/',
            {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            }
          );
          const basket = response.data.find(basket => basket.basket_id === basketId);
          setbasketData(basket);
          setUserName(basket.basket_name);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      getbasketData();
    }
  }, [fetchDone, accessToken, basketData]);

  return (
    <div>
      <NavBar userName={userName} setUserName ={setUserName}/>
      <Project handleProjectData={handleProjectData} />
      {projectData && (
        <div>
          <h3>Project Data:</h3>
          <pre>{JSON.stringify(projectData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default Screen1;