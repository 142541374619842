import React, { useState, useEffect, useRef } from 'react';
import '../stylesheets/Project.css';
import { Link } from 'react-router-dom';

function Popup({ handleClose, show, handleProjectData }) {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';
  const projectNameRef = useRef(null);

  useEffect(() => {
    if (show) {
      projectNameRef.current.focus();
    }
  }, [show]);

  const [projectName, setProjectName] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const maxDimensionValue = 70;
  
  useEffect(() => {
    if (projectName) {
      setIsButtonDisabled(false);
      setErrorMessage('');
    } else {
      setIsButtonDisabled(true);
    }
  }, [projectName]);

  const handleClick = () => {
    if (isButtonDisabled) {
      setErrorMessage('Please complete all required fields');
    }
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <h2 style={{ color: 'grey' }}>NEW PROJECT</h2>        
        <form className='formPopUpP'>
          {errorMessage && (
            <p className="error-message">{errorMessage}</p>
          )}
          <label className='labelPopUpP' htmlFor="projectName">Name<span style={{ color: '#ec655f' }}>*</span></label>
          <input className='inputPopUpP' type="text" id="projectName" name="projectName" 
          autoComplete="off" placeholder='Project' ref={projectNameRef} value={projectName}
          onChange={(e) => setProjectName(e.target.value)}/>

          <label className='labelPopUpP' htmlFor="device">Device</label>
          <select className='selectPopUpP' id="device" name="device">
          <option value="0">Augmented Reality</option>
          <option value="1">Virtual Production</option>
          </select>

          <label className='labelPopUpP' htmlFor="units">Units</label>
          <select className='selectPopUpP' id="units" name="units">
            <option value="Meters">Meters</option>
          </select>
        <Link to={isButtonDisabled ? "#" : "/xrcapsule"}>
        <button
            className="popup-button-inP"
            onClick={() => {
              handleClick();
              if (!isButtonDisabled) {
                const projectName = document.getElementById('projectName').value;
                const device = document.getElementById('device').value;
                const units = document.getElementById('units').value;
                const deviceSelect = document.getElementById('device');
                const deviceText = deviceSelect.options[deviceSelect.selectedIndex].text;                
                var dimensionW = 0;
                var dimensionH = 0;

                if(parseInt(device) == 0){
                  dimensionW=5;
                  dimensionH=5;
                }
                else{
                  dimensionW=30;
                  dimensionH=30;
                }
                const projectData = {
                  projectName,
                  device,
                  deviceText,
                  units,
                  dimensions: { w: dimensionW, h: dimensionH },
                };

                handleProjectData(projectData);

                handleClose();
              }
            }}
          >
            Create
          </button>
        </Link>
        </form>
      </section>
    </div>
  );
}

function Project({ handleProjectData }) {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    togglePopup();
  }, []);

  return (
    <div className="App-project">
      <div className="gradient-backgroundP">
        <Popup
          show={showPopup}
          handleClose={togglePopup}
          handleProjectData={handleProjectData}
        />
      </div>
    </div>
  );
}

export default Project;
