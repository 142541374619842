import React, { useState, useEffect } from 'react';
import '../stylesheets/ObjectData.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const ObjectData = ({ selectedModel, setSelectedModel, modelFiles, setModelFiles }) => {
  
  useEffect(() => {
    if (selectedModel !== null && selectedModel !== undefined) {
      console.log("selectedModel:", selectedModel);
      if (!selectedModel.modelName) {
        selectedModel.modelName = `Asset ${selectedModel.index}`;
        setSelectedModel({ ...selectedModel });
      }
      const prevSelectedAssetItemIds = Array.from(document.querySelectorAll('.asset-item[selected]'));
      prevSelectedAssetItemIds.forEach(assetItem => {
        assetItem.removeAttribute('selected');
        assetItem.style.backgroundColor = null;
        assetItem.style.color = 'grey';
      });
  
      const assetItemId = `asset-item-${selectedModel.index}`;
      const assetItemElement = document.getElementById(assetItemId);
      if (assetItemElement) {
        assetItemElement.setAttribute('selected', true);
        assetItemElement.style.backgroundColor = '#3a3e47';
        assetItemElement.style.color = '#ccc';
      }
    }
  }, [selectedModel]);

  const [modelTriggerInfo, setModelTriggerInfo] = useState({});

  const updateTriggerInfo = (modelIndex, triggerInfo) => {
    setModelTriggerInfo(prevTriggerInfo => ({
      ...prevTriggerInfo,
      [modelIndex]: triggerInfo
    }));
  };

  const getTriggerInfoForModel = (modelIndex) => {
    return modelTriggerInfo[modelIndex] || [];
  };

  const updateTriggersInModelFiles = (modelIndex, newTriggers) => {
    setModelFiles(prevModelFiles => {
      const updatedModelFiles = [...prevModelFiles];
      updatedModelFiles[modelIndex] = {
        ...updatedModelFiles[modelIndex],
        triggers: newTriggers
      };
      return updatedModelFiles;
    });
  };

  const handleTriggerButtonUpClick = () => {
    if (selectedModel && selectedModel.index != null) {
      const modelIndex = selectedModel.index;
      const triggerInfoForModel = getTriggerInfoForModel(modelIndex);
  
      if (triggerInfoForModel.length < 3) {
        let timeValue = document.getElementById('selector-time').value;
        const timeText = document.getElementById('selector-time').options[document.getElementById('selector-time').selectedIndex].text;
        const actionValue = document.getElementById('selector-action').value;
        const actionText = document.getElementById('selector-action').options[document.getElementById('selector-action').selectedIndex].text;
        
        if(parseInt(actionValue) === 2){
          timeValue = "00:00:41";
        }

        const newTriggerInfo = [...triggerInfoForModel, { time: timeValue, action: actionValue, timeText: timeText, actionText: actionText }];
        updateTriggerInfo(modelIndex, newTriggerInfo);
        updateTriggersInModelFiles(modelIndex, newTriggerInfo);
      }
    }
  };

  const handleTriggerButtonDownClick = () => {
    if (selectedModel && selectedModel.index != null) {
      const modelIndex = selectedModel.index;
      const newTriggerInfo = [...getTriggerInfoForModel(modelIndex)];
      newTriggerInfo.pop();
      updateTriggerInfo(modelIndex, newTriggerInfo);
      updateTriggersInModelFiles(modelIndex, newTriggerInfo);
    }
  };

  const handleApply = () => {
    if (selectedModel && selectedModel.index != null) {
      const modelIndex = selectedModel.index;
      const updatedPosition = [
        parseFloat(document.getElementById('position-x').value).toFixed(3),
        parseFloat(document.getElementById('position-y').value).toFixed(3),
        parseFloat(document.getElementById('position-z').value).toFixed(3)
      ];

      const updatedRotation = [
        parseFloat(document.getElementById('rotation-x').value).toFixed(3),
        parseFloat(document.getElementById('rotation-y').value).toFixed(3),
        parseFloat(document.getElementById('rotation-z').value).toFixed(3)
      ];

      const updatedScale = [
        parseFloat(document.getElementById('scale-x').value).toFixed(3),
        parseFloat(document.getElementById('scale-y').value).toFixed(3),
        parseFloat(document.getElementById('scale-z').value).toFixed(3)
      ];
  
      setSelectedModel((prevSelectedModel) => {
        if (prevSelectedModel.index === modelIndex) {
          return {
            ...prevSelectedModel,
            currentPosition: updatedPosition, 
            currentRotation: updatedRotation,
            currentScale: updatedScale
          };
        }
        return prevSelectedModel; 
      });
  
      setModelFiles(prevModelFiles => {
        const updatedModelFiles = [...prevModelFiles];
        updatedModelFiles[modelIndex] = {
          ...updatedModelFiles[modelIndex],
          position: updatedPosition,
          rotation: updatedRotation,
          scale: updatedScale
        };
        return updatedModelFiles;
      });
    }
  };

  return (
    <div className='object-data-container'>
      <h3>Inspector</h3>
      <hr style={{ borderColor: '#3a3e47', marginTop: '20px', marginBottom: '35px' }} />
      <div>
        {selectedModel !== null && selectedModel !== undefined ? (
          <>
          <div className='field-container'>
            <div className='field'>
              <label htmlFor="positionP">P</label>
              <input
                id="position-x"
                className='rounded-inspector'
                type="number"
                value={parseFloat(selectedModel.currentPosition[0]).toFixed(3)}
                onChange={handleApply}
              />
              <input
                id="position-y"
                className='rounded-inspector'
                type="number"
                value={parseFloat(selectedModel.currentPosition[1]).toFixed(3)}
                onChange={handleApply}
              />
              <input
                id="position-z"
                className='rounded-inspector'
                type="number"
                value={parseFloat(selectedModel.currentPosition[2]).toFixed(3)}
                onChange={handleApply}
              />
            </div>
            <div className='field'>
            <label htmlFor="position">R</label>
              <input
                id="rotation-x"
                className='rounded-inspector'
                type="number"
                value={parseFloat(selectedModel.currentRotation[0]).toFixed(3)}
                onChange={handleApply}
              />
              <input
                id="rotation-y"
                className='rounded-inspector'
                type="number"
                value={parseFloat(selectedModel.currentRotation[1]).toFixed(3)}
                onChange={handleApply}
              />
              <input
                id="rotation-z"
                className='rounded-inspector'
                type="number"
                value={parseFloat(selectedModel.currentRotation[2]).toFixed(3)}
                onChange={handleApply}
              />
            </div>
            <div className='field'>
              <label htmlFor="position">S</label>
              <input
                id="scale-x"
                className='rounded-inspector'
                type="number"
                step="0.001"
                min="0.5"
                max="8"
                value={parseFloat(selectedModel.currentScale[0]).toFixed(3)}
                onChange={handleApply}
              />
              <input
                id="scale-y"
                className='rounded-inspector'
                type="number"
                step="0.001"
                min="0.5"
                max="8"
                value={parseFloat(selectedModel.currentScale[1]).toFixed(3)}
                onChange={handleApply}
              />
              <input
                id="scale-z"
                className='rounded-inspector'
                type="number"
                step="0.001"
                min="0.5"
                max="8"
                value={parseFloat(selectedModel.currentScale[2]).toFixed(3)}
                onChange={handleApply}
              />
            </div>
            <br />
          </div>
          <h3>Triggers</h3>
          <hr style={{ borderColor: '#3a3e47', marginTop: '20px', marginBottom: '35px' }} />
          <div className='field'>
            <select id="selector-time" className='select-trigger'>
              <option value="00:00:39">Time</option>
            </select>
            <select id="selector-action" className='select-trigger' defaultValue="360.000">
              <option value="360.000">Rotation</option>
              <option value="2.000">Scale</option>
            </select>
            <div id="trigger-button-up" className='select-trigger-button-up' onClick={handleTriggerButtonUpClick}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
            <div id="trigger-button-down" className='select-trigger-button-down' onClick={handleTriggerButtonDownClick}>
              <FontAwesomeIcon icon={faMinus} />
            </div>
          </div>
          <div className='field-container'>
            <div>
            {selectedModel && selectedModel.index != null && getTriggerInfoForModel(selectedModel.index).map((info, index) => (
              <div className='triggers-container' key={index}>
                <div className='triggers-field'>
                  <div className='triggers-div'>
                    <label className='triggers-label' htmlFor="position">{info.timeText}_{index}</label>
                  </div>
                  <div>
                    <input className="rounded-input-trigger" type="text" value={info.time} readOnly />
                  </div>
                </div>
                <div className='triggers-field'>
                  <div className='triggers-div'>
                    <label className='triggers-label' htmlFor="position">{info.actionText}_{index}</label>
                  </div>
                  <div>
                    <input className="rounded-input-trigger" type="text" value={info.action} readOnly />
                  </div>
              </div>
              </div>
            ))}
            </div>
          </div>
          <div>  
            <button className='deselect-button' onClick={() => {
              const assetItemId2 = `asset-item-${selectedModel.index}`;
              const assetItemElement2 = document.getElementById(assetItemId2);
              if (assetItemElement2) {
                assetItemElement2.style.backgroundColor = null;
                assetItemElement2.style.color = '#3a3e47';
              }
              setSelectedModel(null);
            }}>Deselect Asset</button>
          </div>
        </>
        ) : (
          <span>Select an asset for more details...</span>
        )}
      </div>
    </div>
  );
};

export default ObjectData;
