import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../stylesheets/AssetData.css';

const AssetData = ({ hideModel, hiddenModels, modelFiles}) => {

  const iconStyle1 = {

  };
  
  return (
    <div className="asset-data">
      <h3>Assets</h3>
      <div className="asset-list">
        {modelFiles.map((modelFile, index) => (
          <div key={index} className="asset-item" 
          id={`asset-item-${index}`}>{modelFile.name}
            <FontAwesomeIcon 
              icon={hiddenModels.includes(index) ? faEyeSlash : faEye} 
              className='iconStyleAsset' onClick={() => hideModel(index)}
            />          
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssetData;
